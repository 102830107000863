button {
	overflow: visible;
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5;
	text-transform: none;
	cursor: pointer;
	background: transparent;
	border: 0;
	border-radius: 0;
	-webkit-appearance: button;
}

button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}


.fonts-loaded [class^="button"] {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
}

[class^="button"] {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 10px 25px;
	font-family: inherit;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: #fff;
	background: var(--red);
	transition: var(--transition);
	border: 0;
	border-radius: 100px;
}

	[class^="button"]:focus,
	[class^="button"]:hover {
		color: #fff;
		background-color: var(--black);
	}

	[class^="button"]:disabled,
	[class^="button"][class*="disabled"] {
		pointer-events: none;
		opacity: .66;
	}


	/* a11y */
	[class^="button"]:active,
	[class^="button"]:focus {
		transition: none;
		outline: 2px dotted var(--black);
		outline-offset: 2px;
	}


[class^="button"][class*="--block"] {
	display: block;
	margin-top: 25px;
}


[class^="button"][class*="--black-33"] {
	background-color: var(--black--33);
}

[class^="button"][class*="--black-33"]:focus,
[class^="button"][class*="--black-33"]:hover {
	background-color: var(--black);
}


[class^="download-button"] a {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 5px 25px;
	font-family: inherit;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
	text-decoration: none;
	cursor: pointer;
	color: #fff;
	background: var(--red);
	transition: var(--transition);
	border: 0;
	border-radius: 100px;
}

	[class^="download-button"] a:focus,
	[class^="download-button"] a:hover {
		color: #fff;
		background-color: var(--black);
	}

	[class^="download-button"]:disabled a,
	[class^="download-button"][class*="disabled"] a {
		pointer-events: none;
		opacity: .66;
	}

	[class^="download-button"] svg {
		position: absolute;
		top: 0;
		left: 0;
	}

	[class^="download-button"] .instruction {
		display: block;
		padding-left: 35px;
		font-size: 12px;
	}

	[class^="download-button"] .name {
		display: block;
		padding-left: 35px;
	}

	.fonts-loaded [class^="download-button"] .name {
		font-family: HelveticaNeueLTStd-BlkCn;
		font-weight: normal;
	}


	[class^="download-button"][class*="--white"] a {
		color: var(--red);
		background-color: #fff;
	}

	[class^="download-button"][class*="--white"] a:focus,
	[class^="download-button"][class*="--white"] a:hover {
		color: #fff;
		background-color: var(--black);
	}
