:root {
	--red: #e41f13;
	--red--66: rgba(228,31,19,.66);

	--black: #000a00;
	--black-light: #2a2a28;
	--black-lighter: #373834;
	--black--33: rgba(0,10,0,.33);
	--black--66: rgba(0,10,0,.66);

	--grey: #73766e;
	--grey-light: #686b63;
	--grey-lighter: #b4b4b4;
	--grey-lightest: #d7d8d6;
	--grey--88: rgba(115,118,110,.88);

	--background: #f1f1f0;

	--shadow: 2px 2px 15px var(--black--33) ;
	--shadow--active: 0 0 0 5px var(--black);

	--transition: all .6s cubic-bezier(.54,.1,0,.99);
	--menu-transition: all .2s ease-in-out;
}
