
.logo--top {
	position: absolute;
	z-index: 2;
	top: 0;
	left: -1px;
	width: 375px;
	height: 53px;
	background-image: url("../images/angle-corner.svg");
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

@media (min-width: 1280px) {
	.logo--top {
		width: 614px;
		height: 87px;
	}
}

.logo--top .ico {
	position: absolute;
	z-index: 2;
	top: 10px;
	left: 25px;
}

@media (min-width: 1280px) {
	.logo--top .ico {
		top: 21px;
		left: 76px;
	}
}


.logo--bottom {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background-image: url("../images/angle-footer-mobile.svg");
	background-position: top left;
	background-repeat: no-repeat;
}

@media (min-width: 376px) {
	.logo--bottom {
		background-image: url("../images/angle-corner.svg");
	}
}

@media (min-width: 1280px) {
	.logo--bottom {
		position: absolute;
		bottom: 0;
		width: 180px;
		height: 100%;
		background-image: url("../images/angle-footer-desktop.svg");
		background-size: cover;
	}
}

	.logo--bottom .ico {
		position: absolute;
		top: 36px;
    	left: calc(50% - 40px);
	}

	@media (min-width: 376px) {
		.logo--bottom .ico {
			top: 42px;
			left: 25px;
		}
	}

	@media (min-width: 1280px) {
		.logo--bottom .ico {
			top: 46px;
			left: 47px;
		}
	}


.logo svg {
	width: 87.11px;
	height: 53.37px;
}

@media (min-width: 1280px) {
	.logo svg {
		width: 119.86px;
		height: 73.75px;
	}
}
