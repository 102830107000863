.poster {
	position: relative;
	overflow: hidden;
}

	.poster-content {
		color: #fff;
		background-color: var(--grey);
	}

	@media (min-width: 1024px) {
		.poster-content {
			position: absolute;
			z-index: 1;
			top: -50px;
			left: -25px;
			bottom: -50px;
			width: 33.3333%;
			padding-top: 50px;
			padding-left: 25px;
			padding-bottom: 50px;
			background-color: var(--grey--88);
			transform: rotate(-7deg);
		}
	}

		.poster-content .contain {
			padding: 25px;
		}

		@media (min-width: 1024px) {
			.poster-content .contain {
				transform: rotate(7deg);
			}
		}

		@media (min-width: 1280px) {
			.poster-content .contain {
				padding: 50px;
				font-size: calc(18px + .25vw);
			}
		}


.poster-video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
	background-image: url("../svg/play.svg");
	background-position: center center;
	background-repeat: no-repeat;
	transition: all .35s;
}

.poster-video:hover {
	background-color: rgba(0,0,0,.50);
}
