.mask {
	position: relative;
	overflow: hidden;
}

.mask::before {
	content: "";
	position: absolute;
	z-index: 1;
	background: #fff;
	transform-origin: 100% 0;
	backface-visibility: hidden;
}

@media (max-width: 767px) {
	.mask::before {
		bottom: 0;
		left: 0;
		width: 100%;
		height: 14%;
		transform: skewY(-8deg);
	}
}

@media (min-width: 768px) {
	.mask::before {
		top: 0;
		left: -14%;
		width: 14%;
		height: 100%;
		transform: skewX(8deg);
	}
}


figure {
	margin: 0 0 25px;
	overflow: hidden;
}

	figure img {
		display: block;
	}

	figure.align-center img {
		margin-left: auto;
		margin-right: auto;
	}

	figure figcaption {
		padding: 8px 16px;
		font-size: 12px;
		color: var(--grey);
	}


.gallery {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: -25px;
	margin-right: -25px;
	margin-bottom: 25px;
}

@media (min-width: 768px) {
	.gallery {
		margin-left: -50px;
		margin-right: -50px;
		margin-bottom: 50px;
	}
}

	.gallery-item {
		flex: 1 1 auto;
		margin: 0;
		padding: 25px;
	}

	@media (min-width: 768px) {
		.gallery-item {
			padding: 50px;
		}
	}

		.gallery-item img {
			width: 100%;
		}

	.gallery-columns-1 .gallery-item,
	.gallery-columns-5 .gallery-item {
		width: 100%;
	}

	.gallery-columns-2 .gallery-item,
	.gallery-columns-4 .gallery-item,
	.gallery-columns-6 .gallery-item,
	.gallery-columns-8 .gallery-item {
		width: 50%;
	}

	.gallery-columns-3 .gallery-item,
	.gallery-columns-6 .gallery-item,
	.gallery-columns-9 .gallery-item {
		width: 33.3333%;
	}

	@media (min-width: 768px) {
		.gallery-columns-4 .gallery-item {
			width: 25%;
		}

		.gallery-columns-5 .gallery-item {
			width: 20%;
		}

		.gallery-columns-6 .gallery-item {
			width: 16.6667%;
		}
	}

	@media (min-width: 1280px) {
		.gallery-columns-7 .gallery-item {
			width: 14.2857%;
		}

		.gallery-columns-8 .gallery-item {
			width: 12.5%;
		}

		.gallery-columns-9 .gallery-item {
			width: 11.1111%;
		}
	}
