#search {
	max-width: 860px;
	margin: 0 auto;
}

	#search ol {
		list-style: none;
	}

	#search li {
		margin-bottom: 25px;
	}
