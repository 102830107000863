blockquote {
	quotes: "\201C""\2018";
	margin-bottom: 50px;
	font-size: 18px;
}

.fonts-loaded blockquote {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
}

blockquote:last-child {
	margin-bottom: 0;
}

	blockquote::before {
		content: open-quote;
		font-size: 40px;
		line-height: 10px;
		margin-right: 5px;
		vertical-align: -20px;
		color: var(--red);
	}

	blockquote > * {
		padding-left: 25px;
	}

	blockquote > p:last-of-type {
		margin-bottom: 0;
	}

	blockquote cite {
		display: block;
		margin-top: 10px;
		font-size: 14px;
		font-style: normal;
		color: var(--grey-light);
	}

	.fonts-loaded blockquote cite {
		font-family: HelveticaNeueLTStd-Lt;
	}

	blockquote cite::before {
		content: "";
		display: inline-block;
		width: 16px;
		height: 1px;
		vertical-align: middle;
		margin-right: 5px;
		background-color: var(--grey-lighter);
	}


.pull-quote {
	padding: 25px;
	color: #fff;
	background-color: var(--red);
}

	.pull-quote::before {
		color: #fff;
	}

	@media (min-width: 1024px) {
		.pull-quote::before {
			font-size: 60px;
			line-height: 20px;
			margin-right: 5px;
			vertical-align: -30px;
		}
	}

		.pull-quote > * {
			padding-left: 0;
		}

		.pull-quote cite {
			color:  #fff;
		}

			.pull-quote cite::before {
				background-color: #fff;
			}


@media (max-width: 767px) {
	.pull-quote--image {
		padding-bottom: 100px;
	}
}


@media (min-width: 768px) {
	.pull-quote--left,
	.pull-quote--right {
		position: absolute;
		z-index: 1;
		bottom: 25px;
		max-width: 360px;
		background-color: var(--red--66);
	}

	.pull-quote--right {
		right: 25px;
	}

	.pull-quote--left {
		left: 25px;
	}
}

@media (min-width: 1024px) {
	.pull-quote--left,
	.pull-quote--right {
		max-width: 400px;
	}
}

@media (min-width: 1400px) {
	.pull-quote--left,
	.pull-quote--right {
		max-width: 500px;
		bottom: 50px;
		font-size: calc(18px + .25vw);
	}

	.pull-quote--right {
		right: 50px;
	}

	.pull-quote--left {
		left: 50px;
	}
}

