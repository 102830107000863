.callout {
	position: relative;
	padding: 25px;
	text-align: center;
}

	@media (min-width: 768px) {
		.callout {
			margin-top: 50px;
			padding: 25px 50px;
		}
	}

	.callout::before {
		content: "";
		position: absolute;
		top: -5px;
		left: 40%;
		display: block;
		width: 20%;
		height: 5px;
		margin: 0 auto;
		background-color: var(--red);
	}
