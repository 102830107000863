.menu-small {
	display: none;
	margin: 20px 0 10px auto;
}

@media (min-width: 1280px) {
	.menu-small {
		display: flex;
	}
}

	.menu-small a + a {
		margin-left: 1px;
	}

	.menu-small a {
		display: block;
		padding: 18px 25px;
		font-size: 16px;
		color: #fff;
		text-transform: uppercase;
		background-color: var(--black--33);
	}

	.menu-small a:focus,
	.menu-small a:hover,
	.menu-small [aria-current="page"] {
		background-color: var(--black);
	}

	.menu-small a:last-child {
		background-color: var(--red--66);
	}

	.menu-small a:last-child:focus,
	.menu-small a:last-child:hover {
		background-color: var(--red);
	}


	.menu-small--solid a {
		background-color: var(--grey);
	}

	.menu-small--solid a:last-child {
		background-color: var(--red);
	}


.menu-button {
	display: none;
}

.menu-button.enhanced {
	position: relative;
	z-index: 2;
	display: block;
	margin: 10px 0 0 auto;
	padding: 20px;
	background-color: var(--black--66);
}

@media (min-width: 1024px) {
	.menu-button.enhanced {
		margin: 20px 0 10px auto;
	}
}

@media (min-width: 1280px) {
	.menu-button.enhanced {
		margin: 20px 0 10px 1px;
	}
}


.menu-button--solid.enhanced {
	background-color: var(--black);
}


	/* a11y */
	.menu-button.enhanced:active,
	.menu-button.enhanced:focus {
		outline: 2px dotted var(--black);
		outline-offset: 1px;
	}

	.menu-button.enhanced .burger {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 20px;
		height: 20px;
		cursor: pointer;
		transition: transform 330ms ease-out;
	}

	.menu-button.enhanced[aria-expanded="true"] .burger {
		transform: rotate(-45deg);
	}

		.menu-button.enhanced .line {
			width: 100%;
			height: 2px;
			background-color: #fff;
			border-radius: 1px;
		}

		.menu-button.enhanced .line01,
		.menu-button.enhanced .line03 {
			width: 50%;
		}

		.menu-button.enhanced .line01 {
			align-self: flex-end;
			transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
			transform-origin: left;
		}

		.menu-button.enhanced[aria-expanded="true"] .line01 {
			transform: rotate(-90deg) translateX(-9px);
		}

		.menu-button.enhanced .line03 {
			transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
			transform-origin: right;
		}

		.menu-button.enhanced[aria-expanded="true"] .line03 {
			transform: rotate(-90deg) translateX(9px);
		}

		@media (prefers-reduced-motion: reduce) {
			.menu-button.enhanced .burger,
			.menu-button.enhanced .line01,
			.menu-button.enhanced .line03 {
				transition: none;
			}
		}


.menu-is-open {
	position: fixed;
	width: 100%;
    height: 100%;
	overflow: hidden;
}


.menu {
	display: none;
}

.enhanced .menu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	background-color: var(--grey);
	transition: var(--menu-transition);
	overflow-y: auto;
    -webkit-overflow-scrolling: touch;
	pointer-events: none;
	opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
	.enhanced .menu {
		transition: none;
	}
}

.enhanced .menu--open {
	pointer-events: auto;
	opacity: 1;
}

	.menu > ul {
		padding: 100px 25px;
	}

	@media (max-width: 767px) {
		.menu > ul {
			width: 100%;
		}
	}

	@media (min-width: 768px) {
		.menu > ul {
			padding-top: 200px;
		}
	}

		.menu > ul > li {
			padding: 10px 0;
			border-top: 2px solid var(--grey-light);
		}

		@media (min-width: 768px) {
			.menu > ul > li {
				display: flex;
				align-items: center;
			}
		}

		.menu > ul > li:last-child {
			border-bottom: 2px solid var(--grey-light);
		}

			.menu > ul > li > a {
				display: block;
				font-size: calc(20px + .2vw);
				color: var(--grey-lighter);
				transition: var(--transition);
			}

			@media (min-width: 768px) {
				.menu > ul > li > a {
					display: inline-block;
					width: 160px;
				}
			}

				.menu > ul > li > a:focus,
				.menu > ul > li > a:hover {
					padding-left: 25px;
				}

				.menu > ul > li > a:focus,
				.menu > ul > li > a:hover,
				.menu > ul > li > [aria-current="page"] {
					color: #fff;
				}

				.fonts-loaded .menu > ul li a {
					font-family: HelveticaNeueLTStd-BlkCn;
				}


	.menu > ul ul {
		display: flex;
	}

	@media (max-width: 767px) {
		.menu > ul ul {
			flex-wrap: wrap;
			margin: 0 -10px;
		}
	}

		.menu > ul ul li {
			padding: 0 10px;
		}

		@media (max-width: 767px) {
			.menu > ul ul li {
				padding: 5px 10px;
			}

			.menu [data-nav-page="news"] > ul,
			.menu [data-nav-page="impact"] > ul {
				display: none;
			}
		}

			.menu > ul ul li a {
				font-size: 16px;
				color: var(--grey-lighter);
				transition: var(--transition);
			}

			.menu > ul ul li a:focus,
			.menu > ul ul li a:hover,
			.menu > ul ul li [aria-current="page"] {
				color: #fff;
			}

			.fonts-loaded .menu > ul ul li a {
				font-family: HelveticaNeueLTStd-Md;
			}


.fonts-loaded .filter {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
}

.filter {
	margin-bottom: 50px;
	text-align: center;
}

	@media (max-width: 767px) {
		.filter {
			display: none;
		}
	}

	.filter a {
		display: inline-block;
		margin: 0 15px;
		padding: 10px 25px;
		color: var(--red);
		transition: var(--transition);
		border: 2px solid var(--grey-lightest);
		border-radius: 100px;
	}

	.filter a:focus,
	.filter a:hover,
	.filter [aria-current="true"] {
		color: var(--black);
		border-color: var(--red);
	}


.icon-links {
	margin: 10px -5px 0;
}

	@media (max-width: 767px) {
		.icon-links {
			text-align: center;
		}
	}

		.icon-links a {
			display: inline-block;
			width: 38px;
			height: 38px;
			margin: 5px;
		}

			.icon-links a svg {
				width: 38px;
				height: 38px;
				vertical-align: middle;
			}

				.icon-links a svg path {
					fill: var(--red);
				}

				.icon-links a svg circle {
					stroke: var(--grey-lightest);
				}

				.icon-links a svg circle.animate {
					stroke: var(--black);
					stroke-dasharray: 227;
					stroke-dashoffset: 227;
					transition: var(--transition);
				}

				.icon-links a:focus svg circle.animate,
				.icon-links a:hover svg circle.animate {
					stroke-dashoffset: 0;
				}


