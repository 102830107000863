@media (max-width: 767px) {
	.entry {
		padding: 25px;
	}
}

	@media (max-width: 767px) {
		.entry-img {
			margin: -25px;
		}
	}

	@media (max-width: 767px) {
		.entry-headline {
			margin-top: 50px;
			margin-bottom: 25px;
		}
	}

		.entry-meta li {
			margin-bottom: 10px;
		}

		.entry-meta li strong {
			display: inline-block;
			min-width: 100px;
		}

	.entry-quote {
		margin-bottom: 25px;
	}

		@media (min-width: 768px) {
			.entry-quote {
				margin-bottom: 50px;
			}
		}

		@media (min-width: 1280px) {
			.entry-quote {
				position: relative;
				z-index: 1;
			}
		}

		@media (min-width: 1400px) {
			.entry-quote {
				margin-bottom: 75px;
			}

			.entry-quote--offset {
				margin-top: -175px;
			}
		}

	.entry-footer {
		margin-top: 50px;
		margin-left: -25px;
		margin-right: -25px;
		margin-bottom: -25px;
		padding: 25px 50px;
	}

	@media (min-width: 768px) {
		.entry-quote,
		.entry-footer {
			margin-left: -50px !important;
			margin-right: -50px !important;
		}
	}


@supports not (display: grid) {
	.entry {
		padding: 200px 50px 50px;
	}

	.entry-img {
		float: right;
		width: 50%;
		margin-top: -200px;
		margin-right: -50px;
	}

	.entry-headline {
		margin-bottom: 25px;
	}
}


@supports (display: grid) {
	@media (min-width: 768px) {
		.entry {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-gap: 0 50px;
		}
	}

	@media (min-width: 1024px) {
		.entry {
			align-items: center;
		}
	}

	@media (min-width: 1024px) {
		.entry::before,
		.entry::after {
			content: "";
			grid-row: 2 / 200;
			display: block;
			height: 100%;
			background-color: var(--background);
		}

		.entry::before {
			grid-column: 1 / 4;
		}

		.entry::after {
			grid-column: 10 / 13;
		}
	}

	@media (min-width: 768px) {
		.entry-img {
			grid-row: 1;
			grid-column: 6 / 13;
		}
	}

	@media (min-width: 1024px) {
		.entry-img {
			grid-row: 1;
			grid-column: 7 / 13;
		}
	}

		@media (min-width: 768px) {
			.entry-headline {
				grid-column: 2 / 12;
			}

			.has-img .entry-headline {
				grid-row: 1;
				grid-column: 2 / 6;
			}

			.entry-headline ~ * {
				grid-column: 2 / 12;
			}

			.has-img .entry-headline + *:first-of-type {
				margin-top: 50px;
			}

			.entry-headline ~ *:last-child {
				margin-bottom: 50px;
			}

			.entry-headline ~ .entry-footer {
				margin-bottom: 0;
			}
		}

		@media (min-width: 768px) and (max-width: 1023px) {
			.entry-headline {
				margin-top: 80px;
			}
		}

		@media (min-width: 1024px) {
			.entry-headline {
				grid-column: 4 / 10;
			}

			.entry-headline ~ * {
				grid-column: 4 / 10;
			}
		}

		@media (min-width: 1400px) {
			.entry-headline,
			.entry-headline ~ * {
				margin-left: 50px;
				margin-right: 50px;
			}

			.has-img .entry-headline {
				margin-left: 0;
				margin-right: 0;
			}
		}
}


.entry-latest {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

@media (min-width: 768px) {
	.entry-latest {
		display: flex;
		flex-direction: row-reverse;
	}
}

@media (min-width: 1024px) {
	.entry-latest {
		align-items: center;
	}
}

	@media (min-width: 768px) {
		.entry-latest-image,
		.entry-latest-text {
			width: 50%;
		}
	}

	.entry-latest-text {
		position: relative;
		z-index: 2;
		padding: 25px;
	}

	@media (min-width: 768px) {
		.entry-latest-text {
			padding: 90px 15px 50px 50px;
		}
	}

	@media (min-width: 1024px) {
		.entry-latest-text {
			padding: 150px 100px 50px;
		}
	}
