.paths {
	position: relative;
	overflow: hidden;
	margin-left: -25px;
}

@media (min-width: 768px) {
	.paths {
		max-width: 80%;
		margin-left: -50px;
	}
}

@media (min-width: 1024px) {
	.paths {
		max-width: 50%;
		margin-left: -100px;
	}
}

.paths::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 14%;
	height: 100%;
	transform: skewX(8deg);
	background-color: #fff;
	transform-origin: 100% 0;
	backface-visibility: hidden;
}

.bg .paths::after {
	background-color: var(--background);
}

	.path {
		position: relative;
		margin-bottom: 1px;
	}

	.path::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--red--66);
		transform: translateX(calc(-100% + 5px));
		transition: var(--transition);
	}

	.path:hover::before {
		transform: translateX(0);
	}

	.path-title {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 25px;
		margin-top: -10px;
		color: #fff;
	}

	@media (min-width: 768px) {
		.path-title {
			left: 50px;
		}
	}

	@media (min-width: 1024px) {
		.path-title {
			left: 100px;
		}
	}

		.path-title svg {
			width: 16px;
		}

	.path-link {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
