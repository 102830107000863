[class^="grid"][class*="--"] {
	display: flex;
	flex-flow: row wrap;
}

	[class^="grid"][class*="--cells"] > * {
		flex: 0 0 auto;
		width: 100%;
	}

	@media (min-width: 768px) {
		[class^="grid"][class*="--cells-2"] > *,
		[class^="grid"][class*="--cells-4"] > * {
			width: 50%;
		}
	}

	@media (min-width: 1024px) {
		[class^="grid"][class*="--cells-4"] > * {
			width: 25%;
		}
	}

		.cell--padding {
			padding: 25px;
		}

		@media (min-width: 768px) {
			.cell--padding {
				padding: 50px;
			}
		}

		/* Tweak cell padding for 2 cells arrangement only */

		@media (min-width: 1280px) {
			[class^="grid"][class*="--cells-2"] > .cell--padding {
				padding: 50px 100px;
			}
		}


[class^="grid"].center {
	align-items: center;
}


@media (min-width: 768px) {
	[class^="grid"].reverse {
		flex-direction: row-reverse;
	}
}
