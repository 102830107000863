.social-networks {
	margin: 10px -5px 0;
}

	@media (max-width: 767px) {
		.social-networks {
			text-align: center;
		}
	}

		.social-networks a {
			display: inline-block;
			width: 38px;
			height: 38px;
			margin: 5px;
		}

			.social-networks a svg {
				width: 38px;
				height: 38px;
				vertical-align: middle;
			}

				.social-networks a svg path {
					fill: #fff;
				}

				.social-networks a svg circle {
					stroke: var(--grey-lighter);
				}

				.social-networks a svg circle.animate {
					stroke: var(--red);
					stroke-dasharray: 227;
					stroke-dashoffset: 227;
					transition: var(--transition);
				}

				.social-networks a:focus svg circle.animate,
				.social-networks a:hover svg circle.animate {
					stroke-dashoffset: 0;
				}
