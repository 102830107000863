.video + * {
	margin-top: 25px;
}

	/* If the video is in a grid offset with the cell padding */

	.cell--padding > .video {
		position: relative;
		margin: -25px;
	}

	@media (min-width: 768px) {
		.cell--padding > .video {
			margin: -50px;
		}
	}

	@media (min-width: 1280px) {
		[class^="grid"][class*="--cells-2"] > .cell--padding > .video {
			margin: -50px -100px;
		}
	}
