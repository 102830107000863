.banner {
	position: relative;
}

	@media (min-width: 1024px) {
		.banner--top::after {
			content: "";
			position: absolute;
			bottom: 25px;
			left: 0;
			width: 100%;
			height: 60px;
			background-image: url("../svg/scroll.svg");
			background-position: bottom center;
			background-repeat: no-repeat;
			background-size: 20px 60px;
		}
	}

		.banner-text {
			padding: 25px;
			background: #fff;
			border-bottom: 5px solid var(--red);
		}

		@media (min-width: 768px) {
			.banner-text {
				position: absolute;
				z-index: 1;
				bottom: 25px;
				right: 25px;
				max-width: 360px;
				box-shadow: var(--shadow);
			}
		}

		@media (min-width: 1024px) {
			.banner-text {
				max-width: 400px;
			}
		}

		@media (min-width: 1300px) {
			.banner-text {
				max-width: 560px;
				bottom: 50px;
				right: 50px;
			}
		}


.hero {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

@media (min-width: 768px) {
	.hero {
		display: flex;
		flex-direction: row-reverse;
	}
}

@media (min-width: 1024px) {
	.hero {
		align-items: center;
	}
}

	@media (min-width: 768px) {
		.hero-img,
		.hero-text {
			width: 50%;
		}
	}

	.hero-text {
		position: relative;
		z-index: 2;
		padding: 25px;
	}

	@media (min-width: 768px) {
		.hero-text {
			padding: 100px 15px 50px 50px;
		}
	}

	@media (min-width: 1280px) {
		.hero-text {
			padding: 150px 100px 50px;
		}
	}

		.hero-text p:first-of-type {
			font-size: calc(16px + .15vw);
		}
