.fonts-loaded .link,
.fonts-loaded .media-link {
	font-family: HelveticaNeueLTStd-Md;
	font-weight: normal;
}

.link,
.media-link {
	color: var(--red);
	border-bottom: 2px solid var(--grey-lightest);
	transition: var(--transition);
}

.link:focus,
.link:hover,
.media-link:focus,
.media-link:hover {
	border-color: var(--black);
}


.link--no-border {
	border-bottom: none;
}

.link--no-border:focus,
.link--no-border:hover {
	color: var(--black);
}


.bg .link,
.bg .media-link {
	--red: #d60800; /* a11y fix */
}


.fonts-loaded .arrow-link {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
}

.arrow-link {
	position: relative;
	display: inline-block;
	padding-right: 30px;
	font-size: 16px;
	color: var(--black);
	transition: var(--transition);
}

	.arrow-link::after {
		content: "";
		position: absolute;
		top: 5px;
    	right: 0;
		width: 18px;
		height: 12px;
		background-image: url("../svg/arrow-link.svg");
		background-position: center center;
		background-repeat: no-repeat;
		transition: var(--transition);
		transform: translateX(-5px);
	}

	.arrow-link:hover::after {
		transform: translateX(0);
	}

	@media (prefers-reduced-motion: reduce) {
		.arrow-link:hover::after {
			transform: translateX(-5px);;
		}
	}

	.arrow-link + .arrow-link {
		margin-left: 50px;
	}


.fonts-loaded h2 .link,
.fonts-loaded h3 .link,
.fonts-loaded h4 .link,
.fonts-loaded h5 .link {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
	border-bottom: none;
}


.fonts-loaded h2 .link:focus,
.fonts-loaded h2 .link:hover,
.fonts-loaded h3 .link:focus,
.fonts-loaded h3 .link:hover,
.fonts-loaded h4 .link:focus,
.fonts-loaded h4 .link:hover,
.fonts-loaded h5 .link:focus,
.fonts-loaded h5 .link:hover {
	color: var(--black);
}


.heading-link {
	color: var(--red);
	transition: var(--transition);
}

.heading-link:focus,
.heading-link:hover {
	color: var(--black);
}

.heading-link--black {
	color: var(--black);
}
