@import "_root.css";

@import "_base.css";
@import "_links.css";
@import "_text.css";
@import "_tables.css";
@import "_images.css";
@import "_buttons.css";
@import "_form.css";

@import "_page.css";
@import "_logo.css";
@import "_menu.css";
@import "_section.css";
@import "_grid.css";
@import "_entry.css";

@import "_banner-hero.css";
@import "_cards.css";
@import "_callout.css";
@import "_paths.css";
@import "_pagination.css";
@import "_poster.css";
@import "_pull-quote.css";
@import "_search.css";
@import "_sitemap.css";
@import "_slider.css";
@import "_statistic.css";
@import "_social-networks.css";
@import "_video.css";

@import "_youtube-player.css";
@import "_aspect-ratio.css";
@import "_utils.css";

#breakpoint::after {
	content: "xsmall";
	display: none;
}

@media only screen and (max-width: 767px) {
	#breakpoint::after {
		content: "small";
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	#breakpoint::after {
		content: "medium";
		display: none;
	}
}

@media only screen and (min-width: 1000px) {
	#breakpoint::after {
		content: "large";
		display: none;
	}
}
