* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

@media (prefers-reduced-motion: reduce) {
	* {
		--transition: none;
	}
}

article,
aside,
details,
figcaption,
figure,
picture,
footer,
header,
main,
nav,
section {
	display: block
}

template {
	display: none;
}

[hidden] {
	display: none;
}

html {
	font-family: "Helvetica Neue", Helvetica, Arial;
	font-size: 100%;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	background-color: #fff;
}

body {
	overflow-x: hidden;
	margin: 0;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
	color: var(--black);
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
	background-color: transparent;
}

a,
a:hover,
a:focus {
	text-decoration: none
}

img {
	display: inline-block;
	vertical-align: middle;
	border-style: none;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

img,
svg,
video {
	max-width: 100%;
	height: auto;
}

ul {
	list-style: none;
}

b,
strong {
	font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
	font-family: inherit;
	font-weight: 700;
	line-height: 1.25;
	color: inherit;
}
