.page {
	position: relative;
	overflow-x: hidden;
}


.main-header {
	position: relative;
	z-index: 2;
	display: flex;
	margin-bottom: 50px;
}

@media (min-width: 1280px) {
	.main-header {
		height: 90px;
	}
}

.main-header--fixed {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	.main-header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

	.main-header--fixed {
		position: fixed;
	}
}



.main-footer {
	position: relative;
	padding: 100px 25px 50px;
	font-size: 14px;
	color: var(--grey-lighter);
	background-color: var(--black-light);
}

	@media (min-width: 768px) {
		.main-footer {
			padding-left: 50px;
			padding-right: 50px;
		}
	}

	@media (min-width: 1280px) {
		.main-footer {
			padding: 50px 25px 50px 240px;
		}
	}

	.main-footer .colophon {
		margin-top: 50px;
		font-size: 13px;
	}

	.main-footer .signed {
		position: absolute;
		right: 5px;
		bottom: 0;
	}

		.main-footer .signed svg {
			fill: #111111;
		}


	.legal-links {
		margin-top: 25px;
	}

	@media (max-width: 767px) {
		.legal-links {
			column-count: 2;
			column-gap: 15px;
		}
	}

		.legal-links a {
			position: relative;
			display: inline-block;
			padding: 8px 0;
			color: #fff;
		}

		@media (min-width: 768px) {
			.legal-links a + a {
				margin-left: 10px;
			}
		}

		.legal-links a::before {
			content: "";
			position: absolute;
			top: 75%;
			display: block;
			width: 100%;
			height: 1px;
			background: var(--black-lighter);
			transition: var(--transition);
		}

		.legal-links a:focus::before,
		.legal-links a:hover::before {
			background: var(--red);
		}

		.legal-links a::after {
			content: ".";
			display: inline-block;
			font-size: 12px;
			font-weight: 900;
			margin-left: 1px;
			color: var(--grey-lighter);
		}


@media (min-width: 1280px) {
	.policy-work.entries main,
	.young-people.entries main,
	.announcements.entries main {
		padding-top: 50px;
	}
}