.youtube-player {
	position: relative;
	max-width: 100%;
	height: 0;
	overflow: hidden;
	/* Use 75% for 4:3 videos */
	padding-bottom: 56.23%;
	background: #000;
}

	.youtube-player iframe {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
	}

	.youtube-player img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		max-width: 100%;
		width: 100%;
		height: auto;
		display: block;
		margin: auto;
		cursor: pointer;
		border: none;
		transition: all .35s;
	}

	.youtube-player .play {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		cursor: pointer;
		background-image: url("../svg/play.svg");
		background-position: center center;
		background-repeat: no-repeat;
		transition: all .35s;
	}

	@media (max-width: 900px) {
		.youtube-player .play {
			background-size: 25%;
		}
	}

	.youtube-player .play:hover {
		background-color: rgba(0,0,0,.50);
	}
