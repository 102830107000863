.statistic {
	position: relative;
	margin-top: 25px;
	padding-left: 100px;
}

	.statistic svg {
		position: absolute;
		top: -25px;
		left: 0px;
		width: 80px;
		height: 80px;
	}


@media (min-width: 768px) {
	[class^="blocks"] .statistic {
		margin-left: -25px;
	}
}

@media (min-width: 1280px) {
	[class^="blocks"] .statistic {
		margin-left: -50px;
	}
}
