label {
	font-size: 10px;
}

input,
select,
textarea {
	--grey: #495057;
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5;
	border-radius: 0;
	appearance: none;
}

input,
textarea {
	display: inline-block;
	width: 100%;
	margin: 0;
	padding: 10px 25px;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid var(--grey-lightest);
	transition: border-color .15s ease-in-out;
	outline: 0;
}

select {
	display: inline-block;
	width: 100%;
	height: 44px;
	padding: 10px 25px;
	color: var(--grey);
	vertical-align: middle;
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 25px center/8px 10px;
	background-color: #fff;
	border: 1px solid var(--grey-lightest);
	transition: border-color .15s ease-in-out;
	outline: 0;
}

input:focus,
select:focus,
textarea:focus {
	color: var(--grey);
	border-color: var(--grey-lighter);
	outline: 0;
}

::placeholder {
	color: var(--black);
}

:focus::placeholder {
	color: transparent;
}


.form-field {
	margin-bottom: 15px;
}


.search-field {
	display: flex;
}

	.search-field input {
		margin-right: 15px;
		border: 2px solid var(--grey-lightest);
	}


.selection-filter {
	margin-bottom: 50px;
}

	@media (min-width: 768px) {
		.selection-filter {
			display: none;
		}
	}

	.selection-filter select {
		display: inline-block;
		width: 100%;
		padding: 10px 25px;
		font-size: 16px;
		line-height: 1.5;
		vertical-align: middle;
		color: var(--red);
		background: #fff url("../svg/arrow-right-red.svg") no-repeat right 12px center/10px 25px;
		background-clip: padding-box;
		transition: var(--transition);
		border: 2px solid var(--grey-lightest);
		border-radius: 100px;
	}

	.selection-filter select:focus {
		color: var(--black);
		background-color: #fff;
		border-color: var(--red);
		outline: 0;
	}
