.fonts-loaded .lt-font {
	font-family: HelveticaNeueLTStd-Lt;
}

.fonts-loaded .md-font {
	font-family: HelveticaNeueLTStd-Md;
}

.fonts-loaded .blk-font {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
}


h1, h2, h3, h4, h5, h6 {
	margin-bottom: 15px;
}

h1:last-child, h2:last-child, h3:last-child {
	margin-bottom: 0;
}


h1 {
	font-size: calc(16px + 1.5vw);
}

	.home h1 {
		color: var(--red);
	}

h2 {
	font-size: calc(16px + 1vw);
}

h3 {
	font-size: calc(16px + .5vw);
}

h4 {
	font-size: calc(16px + .25vw);
}

h5 {
	font-size: 18px;
}


p {
	margin-bottom: 20px;
}

	p:last-child {
		margin-bottom: 0;
	}


.text {
	margin-bottom: 25px;
	font-size: 14px;
	font-style: normal;
	color: var(--black);
}

	.text--mb0 {
		margin-bottom: 0;
	}

	.text--mute {
		color: var(--grey-light);
	}

	.text--small {
		margin-bottom: 0;
		font-size: 12px;
		line-height: 1.5;
	}

	.text--caps {
		text-transform: uppercase;
	}


@media (max-width: 999px) {
	br {
		display: none;
	}
}


hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	margin-top: 25px;
	margin-bottom: 25px;
	border: 0;
	border-top: 5px solid var(--background);
}


ul.list {
	list-style: none; /* Remove default bullets */
	margin-bottom: 20px;
	padding-left: 1.25em;
}

ul.list:last-child {
	margin-bottom: 0;
}

	ul.list li {
		margin-bottom: 10px;
	}

	ul.list li:last-child {
		margin-bottom: 0;
	}

	ul.list li::before {
		content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		display: inline-block;
		width: 1em;
		margin-left: -1em;
		font-weight: bold;
		color: var(--red);
	}


ol.list {
	margin-bottom: 20px;
	padding-left: 1.25em;
}

ol.list:last-child {
	margin-bottom: 0;
}
