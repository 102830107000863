@media (min-width: 768px) {
	.sitemap {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
	}
}


.sitemap-group {
	margin-top: 20px;
}

@media (max-width: 767px) {
	.sitemap-group {
		text-align: center;
	}

	.sitemap-group + .sitemap-group {
		margin-top: 0;
	}
}

	.sitemap-group li:first-of-type {
		margin: 0 0 15px;
	}

	@media (max-width: 767px) {
		.sitemap-group li:first-of-type ~ li {
			display: none;
		}
	}

	.sitemap-group li:first-of-type a {
		border-bottom: none;
	}

	.sitemap-group li a {
		position: relative;
		display: inline-block;
		padding: 5px 0;
		color: #fff;
	}

	.sitemap-group li a::after {
		content: "";
		position: absolute;
		top: 80%;
		display: block;
		width: 100%;
		height: 1px;
		background: var(--black-lighter);
		transition: var(--transition);
	}

	.sitemap-group li a:focus::after,
	.sitemap-group li a:hover::after {
		background: var(--red);
	}

	.fonts-loaded .sitemap-group li:first-of-type a {
		font-family: HelveticaNeueLTStd-BlkCn;
	}
