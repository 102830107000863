.fonts-loaded .pagination {
	font-family: HelveticaNeueLTStd-BlkCn;
	font-weight: normal;
}

.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 50px auto 0;
	background: #fff;
	cursor: default;
}

	.pagination a,
	.pagination em,
	.pagination > span {
		padding: 10px 25px;
	}

	.pagination a,
	.pagination em {
		font-style: normal;
		text-decoration: none;
		color: var(--red);
	}

	@media (max-width: 767px) {
		.pagination a,
		.pagination em {
			display: none;
		}
	}

	.pagination a:focus,
	.pagination a:hover {
		color: var(--black);
	}

	.pagination .paginate-current {
		color: var(--black);
	}

	.pagination .paginate-next,
	.pagination .paginate-previous {
		position: relative;
		margin: 0 10px;
		text-align: center;
	}

	@media (max-width: 767px) {
		.pagination .paginate-next,
		.pagination .paginate-previous {
			display: inline-block;
			padding: 10px 25px;
			color: var(--red);
			transition: var(--transition);
			border: 2px solid var(--grey-lightest);
			border-radius: 100px;
		}
	}

	.pagination .paginate-next.disabled,
	.pagination .paginate-previous.disabled {
		color: var(--grey-lightest);
	}
