.cards + .cards {
	margin-top: 25px;
}

@media (max-width: 767px) {
	.home .cards + .cards {
		display: none;
	}
}

@media (min-width: 1280px) {
	.cards + .cards {
		margin-top: 50px;
	}
}


.card + .card {
	margin-top: 25px;
}


@supports (display: grid) {
	[class^="cards"][class*="auto-fit"] > ol,
	[class^="cards"][class*="auto-fit"] > ul {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}


	[class^="cards"][class*="auto-fill"] > ol,
	[class^="cards"][class*="auto-fill"] > ul {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}

	@media (min-width: 1280px) {
		[class^="cards"][class*="auto-fit"] > ol,
		[class^="cards"][class*="auto-fit"] > ul,
		[class^="cards"][class*="auto-fill"] > ol,
		[class^="cards"][class*="auto-fill"] > ul {
			grid-column-gap: 50px;
			grid-row-gap: 50px;
		}
	}


	[class^="cards"][class*="fillx5"] > ol,
	[class^="cards"][class*="fillx5"] > ul {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}

	@media (min-width: 525px) {
		[class^="cards"][class*="fillx5"] > ol,
		[class^="cards"][class*="fillx5"] > ul {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (min-width: 1024px) {
		[class^="cards"][class*="fillx5"] > ol,
		[class^="cards"][class*="fillx5"] > ul {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (min-width: 1100px) {
		[class^="cards"][class*="fillx5"] > ol,
		[class^="cards"][class*="fillx5"] > ul {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@media (min-width: 1200px) {
		[class^="cards"][class*="fillx5"] > ol,
		[class^="cards"][class*="fillx5"] > ul {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	.cards .card:only-child {
		max-width: 400px;
	}

	.card + .card {
		margin-top: 0;
	}
}


.card {
	position: relative;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	background-clip: border-box;
	transition: .25s all;
	box-shadow: var(--shadow);
}

	/**
	 * Enable `flex-grow: 1` for decks and groups so that card blocks take up
	 * as much space as possible, ensuring footers are aligned to the bottom.
	 */
	.card-text {
		flex: 1 0 auto;
		padding: 25px;
		background-color: #fff;
	}

	.card-text h3 a::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.card-text p:not(:last-child) {
		margin-bottom: 15px;
	}


.card--clickable {
	transition: var(--transition);
}

.card--clickable:focus,
.card--clickable:hover,
.card--clickable:active {
	--shadow: var(--shadow--active);
}


.card--date {
	padding-right: 25px;
	border-top: 5px solid var(--grey-lightest);
	box-shadow: none;
}

	.card--date::before {
		content: "";
		width: 2px;
		height: 50px;
		margin: 0 auto;
		background-color: var(--black);
	}

	.card--date::after {
		content: "";
		position: absolute;
		top: 45px;
		left: calc(50% - 12.5px);
		width: 10px;
		height: 10px;
		margin-left: -5px;
		background-color: var(--black);
		border-radius: 100%;
	}

	.card--date .card-text {
		background-color: #fff;
	}

		.card--date h3 {
			position: absolute;
			top: -40px;
			left: -12.5px;
			width: 100%;
			text-align: center;
		}

			.card--date h3::after {
				content: "";
				display: block;
				width: 16px;
				height: 16px;
				margin: 0 auto;
				background-color: var(--red);
				border: 2px solid var(--background);
				box-shadow: 0 0 0 2px var(--black);
				border-radius: 100%;
			}


.card--entry .card-text {
	--pos: 15px;
	position: absolute;
	bottom: var(--pos);
	left: var(--pos);
	right: var(--pos);
}


.card--person .card-text {
	--pos: 5px;
	position: absolute;
	bottom: var(--pos);
	left: var(--pos);
	right: var(--pos);
	pointer-events: none;
	color: #fff;
	background: transparent;
}


.card--programme .card-img {
	height: 260px;
	overflow: hidden;
}

@media (min-width: 400px) {
	.card--programme .card-img {
		height: 300px;
	}
}

@media (min-width: 1024px) {
	.card--programme .card-img {
		height: 400px;
	}
}


.card--standard .card-img {
	height: 260px;
	overflow: hidden;
}

@media (min-width: 400px) {
	.card--standard .card-img {
		height: 300px;
	}
}

@media (min-width: 1024px) {
	.card--standard .card-img {
		height: 400px;
	}
}


@media (max-width: 1099px) {
	#latest .card--3,
	#related .card--3 {
		display: none;
	}
}
