.header {
	--pad: 25px;
	padding: var(--pad);
}

@media (min-width: 768px) {
	.header {
		--pad: 50px;
	}
}

@media (min-width: 1280px) {
	.header {
		--pad: 75px 100px;
	}
}

.header + * {
	padding-top: 0 !important;
}


[class^="blocks"] {
	display: flex;
	flex-flow: row wrap;
	margin-left: -25px;
	padding-top: 25px;
	padding-left: 25px;
	padding-right: 25px;
}

@media (min-width: 768px) {
	[class^="blocks"] {
		margin-left: -50px;
		padding-top: 50px;
		padding-left: 50px;
		padding-right: 50px;
	}
}

@media (min-widht: 768px) and (max-width: 1023px) {
	[class^="blocks-3"] {
		justify-content: center;
	}
}

@media (min-width: 1280px) {
	[class^="blocks"] {
		margin-left: -100px;
		padding-top: 100px;
		padding-left: 100px;
		padding-right: 100px;
	}
}


	[class^="blocks"] > * {
		flex: 0 0 auto;
		width: 100%;
	}

	[class^="blocks"] + [class^="blocks"] {
		padding-top: 0;
	}

		@media (min-width: 768px) {
			.blocks-2 > *,
			.blocks-3 > *,
			.blocks-4 > * {
				width: 50%;
			}
		}

		@media (min-width: 1024px) {
			.blocks-3 > * {
				width: 33.3333%;
			}
		}

		.block {
			padding-left: 25px;
			padding-bottom: 25px;
		}

		@media (min-width: 768px) {
			.block {
				padding-left: 50px;
				padding-bottom: 50px;
			}
		}

		@media (min-width: 1280px) {
			.block {
				padding-left: 100px;
				padding-bottom: 100px;
			}
		}
