.slider {
	position: relative;
}

	.slider .scroll {
		padding-top: 50px;
	}

	@media (min-width: 1280px) {
		.slider .scroll {
			padding-top: 100px;
		}
	}

		.slider .scroll > ul {
			scrollbar-color: #201c29;
			scrollbar-width: 10px;
			scrollbar-gutter: always;
			display: flex;
			overflow-x: scroll;
			padding: 15px 0 50px;
			outline: 0;
			-webkit-overflow-scrolling: touch;
		}

		@media (min-width: 1280px) {
			.slider .scroll > ul {
				padding-bottom: 100px;
			}
		}

		/* enable margin from the card:last-child */
		.slider .scroll > ul::after {
			content: "";
			padding: 1px;
		}


			.slider .scroll > ul::-webkit-scrollbar {
				width: 10px;
			}

			.slider .scroll > ul::-webkit-scrollbar:horizontal {
				height: 10px;
			}

			.slider .scroll > ul::-webkit-scrollbar-track {
				background: var(--grey-lightest);
			}

			.slider .scroll > ul::-webkit-scrollbar-thumb {
				background: var(--black);
			}

			.slider .scroll > ul::-webkit-scrollbar-thumb:hover {
				background: var(--black);
			}


		.slider .scroll > #instructions {
			position: absolute;
			left: 0;
			bottom: 5px;
			width: 100%;
			text-align: center;
		}

		@media (min-width: 1024px) {
			.slider .scroll > #instructions {
				bottom: 10px;
			}
		}

		@media (min-width: 1280px) {
			.slider .scroll > #instructions {
				bottom: 25px;
			}
		}

			.slider .scroll > #instructions p {
				font-family: HelveticaNeueLTStd-Md;
				font-size: 14px;
			}

			.slider .scroll > #instructions svg {
				width: 10px;
				stroke: var(--black);
			}


		.slider .scroll .card {
			flex: 1 0 90%;
			margin-right: 15px;
		}

		@media (min-width: 768px) {
			.slider .scroll .card {
				flex: 1 0 50%;
				margin-right: 25px;
			}
		}

		@media (min-width: 1024px) {
			.slider .scroll .card {
				flex: 1 0 43%;
			}
		}

		@media (min-width: 1280px) {
			.slider .scroll .card {
				flex: 1 0 42%;
			}
		}


			.slider .scroll .card:first-child {
				margin-left: 15px;
			}

			@media (min-width: 768px) {
				.slider .scroll .card:first-child {
					margin-left: 50px;
				}
			}

			@media (min-width: 1280px) {
				.slider .scroll .card:first-child {
					margin-left: 100px;
				}
			}


		.our-story .slider .scroll .card {
			margin-top: 50px;
			margin-right: 0;
		}

		@media (min-width: 768px) {
			.our-story .slider .scroll .card {
				flex: 1 0 75%;
			}
		}

		@media (min-width: 1024px) {
			.our-story .slider .scroll .card {
				flex: 1 0 45%;
			}
		}

		@media (min-width: 1280px) {
			.our-story .slider .scroll .card {
				flex: 1 0 25%;
			}
		}
